const presale = [
    "0x29f6f253c499eb420721A347234d9C6FdAD3D5E6",
    "0x04a6571250E7ce3A1660c21a9c398B97645Ad18D",
    "0xCBbC9F20619205A7E74C8309F2B5777cedD33F77",
    "0x95317E0Fb4bd69f83d9Fb21972f6ED44833D03c2",
    "0x7a11f9063317E2f7320818F4Dc888a4818eEE002",
    "0xB2Ef634E5BAb1D7B2136Ed35A91Abc1Adb118281",
    "0xCFCB8E585d1490E01a3776156b98AeE648566CbE",
    "0xD83a259ffdF48D5D2Bea82Fe65A44A1d78Fb1454",
    "0x468cbAd61A63Ef6D753Ac395d99ebACdDcf437AD",
    "0x620910b020fD12E7B1FF8c0D52eda66bf31213d4",
    "0x7A2415b024B32aEeCF4c23CcD022f14De3732bFD",
    "0xdf4a200e713bd0a943e583a345e52ee3cc033d5e",
    "0x8cB7f1A4f44593ec356B11c70C0a977C647C763c",
    "0xc0606EEe8c2d0782e4f06ebD4fCcd5799be6FBd2",
    "0x864D8855584c7fAE8337875630f2A4ebE6e0f6C2",
    "0xb4a4FE00Bd7455c447223e0B66f0f3DA071BD215",
    "0xDa3cC966a3FcebD7F8Bf84209604eFf9281a5f53",
    "0xa021da3af846eadf9539bba8d0d5ac59c87b3ed7",
    "0x862464BbD83d5743F9Cf873C6eA3d187D0f909BF",
    "0xcA610B0783915cd8df283D2A6BfAe3FF29149Fdb",
    "0xBf44C636A4A4Bbe257a87067B25c4C875170e04D",
    "0xa7efbb4a06a680f3b65c5861ec387408ceafbec8",
    "0x50734B1DDBB0011F40E14Df926Df04d0Dd9E7aEf",
    "0x2D7289bCa938ba72859519818DEb8Db52bDF5711",
    "0x2A259F94ede30a0499F1D83Ff534026F6717f4f7",
    "0x130e1CeA0680180D4e92478A7117E977E0af42ce",
    "0xB6B47CE7b922D6B8B024EfC3d835DaCEab0BF627",
    "0x6fE042644Bdc0fb116595A96AaB27fa12d0fc7c0",
    "0x714b1ABE2f2A8B95d2d0FDc472baa7b2c9C79d83",
    "0x1cc1e11dedcbea590232c2d01f50ae6cefc3735f",
    "0xb0b441c9d896ccef6b946f2dadc1bd4ab667370b",
    "0x30A006cEB32C7E591c687301aC905c6C406342a7",
    "0x2d913709FA6B87502af62B3B2F883CFb7024B655",
    "0x4CE1eF3C94013eC0f9607b51a0918426B8dEA90d",
    "0xEeAF3BD19eBe8823ABCc994538A930ee37A6dd3B",
    "0x2598b54c01901f71b7cbb1b4710ce4ca2ac1228f",
    "0xc9eAe131858bB939E5117B283dC2CA70B0589FC0",
    "0x3A9cCdd1603cca78366cb36c0b277A529A030e10",
    "0x72BB13BCFD4cE3882DC4e8fd67C79CdaC6100b35",
    "0x1805Ac17c50C71c916Ec8937f6C5b28ed587A35B",
    "0xd09b97B82AE83107f2C22314d5ef74409c90Ae9B",
    "0x921bbbbd119a6ad5289042DCA100950afa92552e",
    "0xE5127eAdecFd6E31f6FCF1D5FBEaff3F0e788AcC",
    "0x6EC67420c1C2E2FD6F5e27C412bE5af822F7b4f7",
    "0xa7f7b7ABCE31c3235885216a861255dCBA62D773",
    "0xD9e2931B16690C833B7B01984c28A677feB6F2Fb",
    "0xfD2249bd6A703DF6DbE88a0f264288d821788919",
    "0xbE9643D7cDFA24986492d6Df2dEA2acFEA3e7b28",
    "0xEeAF3BD19eBe8823ABCc994538A930ee37A6dd3B",
    "0xc76f646bd53320c768b2751c620d4a6dfcb82762",
    "0xb8b11E47D36EE4fc4B89F6EC92E954100C81F133",
    "0x85687221a7e705F683DfD08864214a7e016659B1",
    "0xB5BC2701c6f5373ECE42F7DF157dBDee8798af67",
    "0xdCf1bF7A55Ec8B85E2e323D14D64D1Cd0CdB5FAf",
    "0xbbB45286603300aA0a396fC1169ffef6d565d6B4",
    "0x4b95cF7CA99086B89F22d98e61E6f16b035B1D05",
    "0xA48881551863C15C23B3A851e7Ae413635Eb7A1D",
    "0xf02D227741F3D51343886264bBc4d7e7F81DDA53",
    "0x8dCAb2E01DEA8Cf1E693b77Aa0df80E02d90B781",
    "0x71a548C09c6d09DF38811c6dF025d281e878258b",
    "0xc8c3b0CBDEcE3596002C731Cb2527E176d84b154",
    "0x418517fc8A44508545Fd81F5f2BaD6f14DE97431",
    "0x01163F6894493F9ecb59c30807A1296b0E00cbBA",
    "0x3488244C0d2D7C388BA835D4E59c45905102c0e9",
    "0x57a5dA33F67511D5301E95935A0Df2637760De59",
    "0x8C4a2B57063AE4b742B4cd6e41946Ba36168865d",
    "0x07b6d7c449a35016B425Ca18FF4DA52c6494DaE8",
    "0xD75Cd3DFeA2D1688961e62b08b333692df0e66ec",
    "0x0B2017Ae31FFD26a32F513365Aa8207882C12e25",
    "0x27a347c2921f9d55c3c9c32fd74dc1a97230d5fc",
    "0xd822512DBD04053646d5Fa21974FAd49291f1aea",
    "0x37781de47983576E45D981038710842666ab5237",
    "0x91073eCdBd064B9BDDC2fE6806a97679D5fA2220",
    "0x2E3c3D37026B96844dC4371614cE45a221b13B54",
    "0x50D7f56b95DB946803435ed762166D8E33ff9477",
    "0xecF1eD246315ed133714B84CA9C3a5c6aDEbf244",
    "0x06b7558064992954fA982771742a4940ed44C3c1",
    "0x0dee12a2548432702e1c275c50b3ad223f9a74be",
    "0x3b15575ba319519f5A3e3258DCb8D82E72F339E4",
    "0xca9C00a0bB2A70429F308C7781c8f9F5624a439a",
    "0x528A9F872BA4500952a3C432A2BA6edffD185F4E",
    "0xE760D050dbfc203CA3B28b1A787Dfd7C7a862253",
    "0xc753f3836f59e3b553985b9b662611b85f4d84e2",
    "0x27886AF189FC7a5cA5311ac9ed615f016084D980",
    "0xfc9dc897B99A97C9CF52f7B7230a533E6f8B6553",
    "0xa867126424924af383490bB3fB521DE8F49FaD0D",
    "0x96D22eCDcfe813Aef8Fe7bB76E1E3b043655a3C8",
    "0xE1c8f5672F69340884C76436A6BD4F10F9988293",
    "0xf66253552256Bd4a77A07B04f06bcDAc70D17CAb",
    "0x5a3E7cD88B4d18EFa56919d6F58336593F5A0778",
    "0x754FD95d9B0B370C641Ef1e6febf64F1293cA70a",
    "0x2dba88cB3B435F99a3e58B6E0fE450e8f1a3F20F",
    "0xDA4745f0254044e4b702C9301737c2A0B8c69A6a",
    "0x8C920BF0B02C6f9c99916aabCD1337de2957ce8f",
    "0xcDDCbD9C2665A71CD4cf5d1FaC0740Bf7643F260",
    "0x51f0102beebdb745ca76b3f85286fb5a4c4b612e",
    "0x1C5762F9899B46c19d4C9B8FBDD38D35856227f3",
    "0xA123b88714B76762EC9E1E35dB6b2637Bc9Aa2a3",
    "0xB8a7d78021A83fb29263D0c121C839C96CF07Fc6",
    "0xbA0860B8e03ED32D9d71417B838D7c327bB2D1e5",
    "0x482470C88Ab8b987beacC002AD7F7C6E83c44995",
    "0x877167231d569c9fcF5e7a5E2b073c7cd41f2cFF",
    "0x41DB2348f54Dbab35908594a3a47e9C3e2C71bfe",
    "0x5d967764f708B212f7D1c366F14368699711B5E6",
    "0xf957A130545C912C2cd5C867B034C3b83F4d29F0",
    "0x484eD6663F4f37EE61931761869338b52Ad936D3",
    "0x7B2DbAEE3791F7bf2cE738634C0a7229a7197666",
    "0x68A9113C5638a7868A3E926776ADA7842683CbF5",
    "0x70b71b98c8b95643462C68e0A396d0a6d15337bf",
    "0xabb9b779893a869B18E0f4E432623d64077788Ff",
    "0x1406bDD2F2A18BfeeC422420646c5b9bAc26Da96",
    "0xc797308ab2d7e643ceb92465b38cc6c001e5a3da",
    "0x34ba7Ce643f78C854E80256e18370746B2D2A653",
    "0xe74b6B2BDA985b36957872ADe1548b2281635FC1",
    "0xc5b009225d42513343c99cf2e4CE60f11ddCf6c1",
    "0xE793B3549Bf6f293151b2378a8b098Ad00c4653e",
    "0xaed8e151086a85d39166b80bb4b4cec2f6328a7a",
    "0x782c1b1d149b2d2cd6Cadb91cC41B8bB8f148537",
    "0x3F358b4E54385ba4fc8dD9EA718d360db5C7fDa5",
    "0xDd0d5b43Bfd9155129930E58522689f31383078C",
    "0xd7062b24e96e82e7506E90730A39175C5e9e68E7",
    "0x7A1a2cD3fd8Fa666271206cc5ad957A1277ABF1F",
    "0x02b70371B72EFd7079b07c5c0186a297Fed10a79",
    "0x7fbeE402F25aCb1A0357Df1d805920bd30157D5C",
    "0x9D7B7d7209f7C1b1787368e2eE34223737ae499C",
    "0x765193a9668CC2D252e10CC9347232231d87e0Aa",
    "0x93d3c68c7451A9f9c9c1Bb86f33A936A4F445eBE",
    "0x4E54bD02aAdf1F921f0250ecbbf7c057f6F1Ec12",
    "0xD89B835e9064D5f623F720bA368232D3C0a42886",
    "0x80d7c15C0C9D08e6EBE770Ec3Eb930f8312084f7",
    "0xc46db2d89327d4c41eb81c43ed5e3dff111f9a8f",
    "0xCc565e74b867c8A1C4b7b07f60134f734DC47547",
    "0xF939A296Cd55532C39F97208748537a18C78BA1F",
    "0x760e7B42c920c2a0FeB58DDD610c14A6Bdd2Ebea",
    "0xCd3D98d28617B919aeF45a88ba33B97b76f43d16",
    "0x27886AF189FC7a5cA5311ac9ed615f016084D980",
    "0x85Bb21414e7E87856C4F4E074f5122839ACF19e5",
    "0x47a6d63Adc8d628675327cA2857345a9f849063B",
    "0x0DeE12A2548432702e1c275c50b3aD223f9a74be",
    "0x2a259f94ede30a0499f1d83ff534026f6717f4f7",
    "0x27a81bBD18F73320F434a96d9F21e312F32Fd086",
    "0x63d418d0a8790bb6737f2f5d80ee79b253d776e2",
    "0xBBeb004D1769Ec8cE5e3957B9D8aca659FBAbd0C",
    "0xC14D121216DD98280F300cA1D75967a7b0488356",
    "0xEc4EE55488eDA04f6e02cdbBb745F50dD612d937"
]

export default presale;