import React from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM from "react-dom";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import App from "./App";
import Redeem from "./Redeem";
import NotFound from "./NotFound"
import "./index.css";

const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const prevTheme = window.localStorage.getItem("theme");

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={prevTheme || "light"}>
          <App/>
        </ThemeSwitcherProvider>
      </Route>
      <Route exact path="/redeem">
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={prevTheme || "light"}>
          <Redeem />
        </ThemeSwitcherProvider>
      </Route>
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root"),
);
